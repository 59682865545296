import axios from 'axios'

import { endpoints } from 'app/constants'

import { IApi } from 'api/interfaces'

export default class ApiDev implements IApi {
  private baseURL: string
  private logoutUser: () => void

  constructor(baseURL: string) {
    this.baseURL = baseURL
    this.logoutUser = () => {}

    this.init()
  }

  init() {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response: any) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response
      },
      (error: any) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        // check if response come from login screen and avoid to redirect
        const comeFromLogin = error.config?.url.includes(endpoints.login)

        if (
          error.response?.status === 401 &&
          !comeFromLogin &&
          process.env.NODE_ENV !== 'development'
        ) {
          this.setBearerToken('')
          this.logoutUser()
          document.location.href = '/'
        }

        return Promise.reject(error)
      }
    )
  }

  public setBearerToken(token: string) {
    // Add a request interceptor
    axios.interceptors.request.use(
      (config: any) => {
        // Do something before request is sent
        token
          ? (config.headers = {
              Authorization: `Bearer ${token}`,
              ...config.headers,
            })
          : delete config.headers.Authorization
        return config
      },
      (error: any) => {
        // Do something with request error
        return Promise.reject(error)
      }
    )
  }

  public setLogoutUser(logoutUser: () => void) {
    this.logoutUser = logoutUser
  }

  public get(url: string, params?: any, config?: any) {
    return axios.get(`${this.baseURL}${url}`, {
      params,
      responseType: config?.responseType,
    })
  }

  public post(url: string, params: any) {
    return axios.post(`${this.baseURL}${url}`, params)
  }

  public postMultipart(url: string, params: any) {
    const headers = {
      // Obtener los headers generados por form-data
      'Content-Type': 'multipart/form-data',
    }
    return axios.post(`${this.baseURL}${url}`, params, { headers })
  }

  public put(url: string, params: any) {
    return axios.put(`${this.baseURL}${url}`, params)
  }
}
